export const ApiService = {
    companySearch,
    getCompany,
    getContact,
    getNotices,
    getDocumentList,
    downloadDocument,
    getCompanyMasterSet,
    getCompanyOfficers,
    getCompanyInsolvenzcheck,
    getCompanywithSearchdetailed
};

function companySearch(keyword, api_key) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        },
        body: JSON.stringify(keyword)
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies`, requestOptions).then(handleResponse);

}

function getCompanywithSearchdetailed(object) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/search/detailed`, requestOptions).then(handleResponse);

}
function getCompanyInsolvenzcheck(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/insolvenzCheck/${company_number}`, requestOptions).then(handleResponse);
}
function getCompanyOfficers(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/officers/${company_number}`, requestOptions).then(handleResponse);
}
function getCompanyMasterSet(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/fullDetails/${company_number}`, requestOptions).then(handleResponse);
}
function getDocumentList(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/document/${company_number}`, requestOptions).then(handleResponse);

}
function getNotices(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/notices/${company_number}`, requestOptions).then(handleResponse);

}
function getContact(company_number, api_key) {
    const requestOptions = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/contact/${company_number}`, requestOptions).then(handleResponse);

}


function getCompany(object, api_key) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/companies/getCompany`, requestOptions).then(handleResponse);

}
function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (response.ok) {

            return data;
        } else {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

    })
}

function downloadDocument(object, document_id, api_key) {
    const requestOptions = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key
        },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/document/${document_id}/base64`, requestOptions).then(handleResponse);



}