export const keyService = {
    getCustomerActiveKey,
    getInvoice,
    generate_invoice,
    getKeys,
    getKey
};
// generate invoice
function generate_invoice(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/invoice/create/${key_id}`, requestOptions).then(handleResponse);

}

// get active key
function getCustomerActiveKey(customer_id) {

    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/getCustomerActiveKey/${customer_id}`, requestOptions).then(handleResponse);

}

// get key by id
function getKey(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/get_key_by_id/${key_id}`, requestOptions).then(handleResponse);

}
// get keys
function getKeys(customer_id) {
    const requestOptions = {
        method: 'GET',

    };
    return fetch(`${process.env.REACT_APP_API_URL}/subscriptions/getKeysByCustomer/${customer_id}`, requestOptions).then(handleResponse);

}

//get invoice 
function getInvoice(key_id) {
    const requestOptions = {
        method: 'GET',

    };
    return fetch(`${process.env.REACT_APP_API_URL}/invoice/getInvoice/${key_id}`, requestOptions).then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                localStorage.removeItem('user_id');
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
