/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'

// Async component
import {
   AsyncPricingUpgradeComponent,
   AsyncUserSettingsComponent,
   AsyncNotificationsComponent,
   AsyncStatisticsComponent,
   APILogComponent
} from 'routes';
import Api from 'routes/Api';
import Search from 'routes/Search';
import Package from 'routes/Package';

export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },
   {
      path: 'pricing',
      component: AsyncPricingUpgradeComponent
   },
   {
      path: 'notifications',
      component: AsyncNotificationsComponent
   },
   {
      path: 'api-log',
      component: APILogComponent
   },
   {
      path: 'package',
      component: Package
   },
   {
      path: 'developers',
      component: Api
   },
   {
      path: 'statistics',
      component: AsyncStatisticsComponent
   }
   ,
   {
      path: 'search',
      component: Search
   }
]