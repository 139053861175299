export default {
   "sidebar.dashboard": "Dashboard",
   "sidebar.package": "Package",
   "sidebar.statistics": "Statistics",
   "sidebar.notifications": " Notifications",
   "sidebar.developers": "Developers",
   "sidebar.apiKeys": "API keys",
   "sidebar.documentation": "Documentation",
   "sidebar.search": "Search",

   "widgets.salutation": "Hi",
   "widgets.welcome": "Welcome back",
   "widgets.quickStats": "Explore your own powerful admin panel and keep track of all activity of Today's Quick Stats",
   "widgets.requests": "Requests",
   "widgets.viewMore": "View More",
   "widgets.newAccounts": "New Accounts",
   "widgets.officers": "Officers",
   "widgets.companies": "Companies",
   "widgets.notices": "Notices",
   "widgets.contact": "Contact",
   "widgets.insolvenzcheck": "Insolvency check",
   "widgets.documentList": "Document List",
   "widgets.masterSet": "MasterSet",
   "widgets.document": "Document",
   "widgets.date": "Date",
   "widgets.title": "Title",
   "widgets.numberOfRequest": "Number of requests",
   "widgets.numberOfRemainingRequests": "Number of remaining requests",

   "component.email": "Email",
   "component.firstName": "First Name",
   "component.lastName": "Last Name",
   "component.salutation": "Gender",
   "component.products": "Products",
   "component.signup": "Sign Up",
   "component.loadmore": "Load More",
   "component.categories": "Categories",
   "component.rating": "Rating",
   "component.priceFilter": "Price Filter",
   "component.productDetails": "Product Details",
   "component.backToProducts": "Back To Products",
   "component.addToCart": "Add To Cart",
   "component.viewCart": "View Cart",
   "component.addtowishlist": "Add To Wishlist",
   "component.yourProductCart": "Your Product Cart",
   "component.continueShopping": "Continue Shopping",
   "component.myShoppingBag": "My Shopping Bag",
   "component.yourBill": "Your Bill",
   "component.saveShoppingBag": "Save Shopping Bag",
   "component.proceedToCheckout": "Proceed To Checkout",
   "component.subtotal": "Subtotal",
   "component.tax": "Tax",
   "component.total": "Total",
   "component.shippingAddress": "Shipping Address",
   "component.payment": "Payment",
   "component.continueToPayment": "Continue To Payment",
   "component.invoice": "Invoice",
   "component.getMembership": "Get Membership",
   "component.alreadyHaveAccount?": "Already Have Account?",
   "component.continueAsGuest?": "Continue As Guest?",
   "components.compose": "Compose",
   "component.firebase": "Firebase",
   "component.auth0": "Auth 0",
   "component.youtubevideoplayer": "Youtube Video Player",
   "component.vimeovideoplayer": "Vimeo Video Player",

   "module.inbox": "Inbox",
   "module.drafts": "Drafts",
   "module.sent": "Sent",
   "module.trash": "Trash",
   "module.spam": "Spam",
   "module.primary": "Primary",
   "module.social": "Social",
   "module.marketing": "Marketing",


   "component.settings": "Settings",
   "component.newPassword": "New password",
   "component.retypePassword": "Confirm password",
   "component.passwordMatchError": "Please enter Confirm Password",
   "component.passwordError": "Your password must be at least 6 characters long",
   "component.home": "Home",
   "component.general": "General",
   "component.account": "Account",
   "component.password": "Password",
   "component.update": "Update",
   "component.faq": "Frequently Asked Questions",
   "component.generalEnquiries": "General Enquiries",
   "component.accounts": "Accounts",
   "component.features": "Features",
   "component.rulesAndPolicies": "Rules And Policies",
   "component.chooseYourPlan": "Choose Your Plan",
   "component.starter": "STARTER",
   "component.professional": "PROFESSIONAL",
   "component.team": "TEAM",
   "component.enterprise": "ENTERPRISE",

}