/**
 * App Language Provider
 * Add more locales here
 */
 import { addLocaleData } from 'react-intl';


 import enLang from './entries/en-US';
 import deLang from './entries/de-DE';
 const AppLocale = {
    en: enLang,
    de: deLang,
 };
 
 addLocaleData(AppLocale.en.data);
 addLocaleData(AppLocale.de.data);
 
 export default AppLocale;