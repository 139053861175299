export default {
    "sidebar.dashboard": "Dashboard",
    "sidebar.package": "Paket",
    "sidebar.notifications": "Protokoll",
    "sidebar.statistics": "Statistik",
    "sidebar.developers": "Entwickler",
    "sidebar.apiKeys": "API Schlüssel",
    "sidebar.documentation": "API Dokumentation",
    "sidebar.search": "SH SUCHE",
    "sidebar.apilog": "API-LOG",
    "sidebar.fullpagemenu":"Ganzseitiges Menü",


    "widgets.salutation": "Hallo",
    "widgets.welcome": "Willkommen zurück",
    "widgets.quickStats": "Erkunden Sie Ihr Panel und verfolgen Sie alle heutigen Aktivitäten",
    "widgets.requests": "Anfragen",
    "widgets.officers": "Geschäftsführer",
    "widgets.companies": "Firmendaten",
    "widgets.notices": "Handelsregister Bekanntmachungen",
    "widgets.contact": "Kontaktdaten",
    "widgets.insolvenzcheck": "Insolvenzcheck",
    "widgets.documentList": "Liste der Dokumente",
    "widgets.document": "Dokumente",
    "widgets.masterSet": "Mastersets",
    "widgets.date": "Datum",
    "widgets.title": "Titel",
    "widgets.sign_out": "Logout",
    "widgets.numberOfRequest": "Gesamtanzahl der Anfragen",
    "widgets.numberOfRemainingRequests": "Anzahl verbleibender Anfragen",



    "component.email": "Email",
    "component.firstName": "Vorname",
    "component.lastName": "Nachname",
    "component.salutation": "Anrede",
    "component.general": "Allgemein",
    "component.account": "Konto",
    "component.password": "Passwort",
    "component.update": "Aktualisieren",
    "component.settings": "Einstellungen",
    "component.newPassword": "Neues Passwort",
    "component.retypePassword": "Passwort bestätigen",
    "component.passwordError": "Ihr Passwort muss mindestens 6 Zeichen lang sein",
    "component.passwordMatchError": "Bitte bestätigen Sie Ihr Passwort",
    "component.invoice": "Bestellungen",
    "component.submit": "Speichern",
    "component.customer_number": "Kundennummer",
    "component.company": "Firma",
    "component.street": "Strasse",
    "component.zip_code": "Postleitzahl ",
    "component.city": "Ort",
    "component.profil": "Mein Profil",
    "component.changePassword": "Passwört ändern",
    "component.contact": "Kontakt",
    "component.lastapilog": "Letzte API-LOG"
}