
export const statisticService = {
    key_statistic,
    getKeyDynamicStats, 
    key_statistique
};

// get key statistiques
function key_statistic(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/statistique/key_statistique/${key_id}`, requestOptions).then(handleResponse);
}
// get key statistiques
function key_statistique(key_id) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${process.env.REACT_APP_API_URL}/statistique/key_statistique/${key_id}`, requestOptions).then(handleResponse);
}
// get Key Dynamic Stats
function getKeyDynamicStats(key_id, object) {
    const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };
    return fetch(`${process.env.REACT_APP_API_URL}/statistique/getKeyDynamicStats/${key_id}`, requestOptions).then(handleResponse);

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                localStorage.removeItem('user_id');
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}