/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => import("routes/Dashboard/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("routes/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Pricing Upgrade
const AsyncPricingUpgradeComponent = Loadable({
	loader: () => import("routes/Pricing/PricingUpgrade"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("routes/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("routes/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncNotificationsComponent = Loadable({
	loader: () => import("routes/Notifications"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const APILogComponent = Loadable({
	loader: () => import("routes/Api-log"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncPackageComponent = Loadable({
	loader: () => import("routes/Package/packageDetails"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncInvoicesComponent = Loadable({
	loader: () => import("routes/Package/invoices"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


const AsyncApiKeysComponent = Loadable({
	loader: () => import("routes/Api/ApiKeys"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncStatisticsComponent = Loadable({
	loader: () => import("routes/Statistics"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncDocumentationComponent = Loadable({
	loader: () => import("routes/Api/Documentation"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
export {
	AsyncDashboard1Component,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncPricingUpgradeComponent,
	AsyncNotificationsComponent,
	AsyncPackageComponent,
	AsyncApiKeysComponent,
	AsyncStatisticsComponent,
	AsyncDocumentationComponent,
	AsyncInvoicesComponent,
	APILogComponent
};