/**
 * Courses Routing File
 */

 import React from 'react'
 import { Route, Switch } from 'react-router-dom'
 import {
     AsyncInvoicesComponent,
     AsyncPackageComponent
 
 } from 'routes';
 
 const Package = ({ match }) => (
     <Switch>
         <Route path={`${match.url}/details`} component={AsyncPackageComponent}></Route>
         <Route path={`${match.url}/invoices`} component={AsyncInvoicesComponent}></Route>
 
     </Switch>
 )
 export default Package;